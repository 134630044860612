<template>
  <div id="PrivacyPolicy">
    <Hero>
      <h1>Pranešimas dėl asmens duomenų tvarkymo</h1>
    </Hero>
    <Sect>
      <Wrap>
        <p>
          UAB „Saulės bendruomenė LT“ svetainėje renkame duomenis apie www.saulesbendruomene.lt lankytojus ir apie UAB „Saulės bendruomenė LT“ potencialus bei esamus klientus su jais sudarytose sutartyse ir/ar teisės aktuose numatytais tikslais.
        </p>
        <p>
          Siekdami užtikrinti tvarkomų Jūsų asmens duomenų apsaugą pagal 2016 m. balandžio 27 d. Europos Parlamento ir Tarybos reglamento (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos reglamentas) (toliau – BDAR), 13 ir 14 straipsnius informuojame, kad Jūs turite daug teisių, kurios leidžia Jums kontroliuoti savo privatumą, ir UAB „Saulės bendruomenė LT“  užtikrina galimybę jomis pasinaudoti. UAB „Saulės bendruomenė LT“ svarbu sudaryti Jums sąlygas naudotis savo teisėmis, tad toliau rasite informaciją, kaip tai galite padaryti.
        </p>

        <h3>Jūsų duomenų valdytojas</h3>
        <p>
          Jūsų asmens duomenų valdytojas yra UAB „Saulės bendruomenė LT“. Su UAB „Saulės bendruomenė LT“ galite susisiekti elektroniniu paštu: info@saulesbendruomene.lt
        </p>

        <h3>Kokius duomenis renka UAB „Saulės bendruomenė LT“ ir kaip juos naudoja (Duomenų tvarkymo tikslai ir teisinis pagrindas)</h3>
        <p>
          UAB „Saulės bendruomenė LT“  tvarko kliento, jei jis yra fizinis asmuo, ir kitų fizinių asmenų (atstovų, darbuotojų ir kt.), taip pat svetainės lankytojų  asmens duomenis šiais tikslais ir teisiniais pagrindais
        </p>

        <h4>1. Siekiant sudaryti ir vykdyti sutartis su klientais</h4>
        <p>
          Numatytas tikslas grindžiamas būtinybe ir teisine pareiga tinkamai, sąžiningai ir efektyviai įvykdyti sutartis su klientais arba būtinybe UAB „Saulės bendruomenė LT“ pasinaudoti iš tokių sutarčių kylančiomis teisėmis. Bendrovė su klientais sudaro sutartis dėl geografiškai nutolusių saulės elektrinių įsigijimo ir jų priežiūros, taip pat dėl elektrinių statybos ant stogų ir/arba žemės sklypuose, kitų paslaugų ir/ar produktų ir/ įrangos įsigijimo, aptarnavimo. Tuo pagrindu klientas savitarnos sistemoje pateikia UAB „Saulės bendruomenė LT“ savo asmens duomenis, kurie yra reikalingi ne tik sutarties su bendrove sudarymui, bet ir jos vykdymui. Teisinis tvarkymo pagrindas yra BDAR 6 str. 1 d. b) ir c) p.
        </p>

        <h4>2. UAB „Saulės bendruomenė LT“ taikomų teisinių prievolių vykdymas</h4>
        <p>
          Atskiroms asmens duomenų tvarkymo operacijoms teisinį pagrindą taip pat gali sudaryti UAB „Saulės bendruomenė LT“ taikomų teisinių prievolių vykdymas. Pavyzdžiui, mokesčių deklaravimo, interesų konflikto vengimo ir kt. Teisinis tvarkymo pagrindas yra BDAR 6 str. 1 d. c) p.
        </p>

        <h4>3. Teisėti UAB „Saulės bendruomenė LT“ interesai </h4>
        <p>
          UAB „Saulės bendruomenė LT“ tvarko asmens duomenis siekiant teisėtų Bendrovės interesų (BDAR 6 str. 1 d. f) p.), t.y.:
        </p>
        <ul>
          <li>Interneto svetainės lankomumo statistikos tikslais BDAR Reglamento 6 str. 1 d. f) p.;</li>
          <li>Teisėtas tiesioginės rinkodaros priemonių (naujienlaiškių, kvietimų, pranešimų) naudojimas grindžiamas teisėtais Bendrovės interesais vykdyti savo veiklos plėtrą (daugiau informacijos žr. Rinkodaros politikoje žemiau). BDAR Reglamento 6 str. 1 d. a) ir f) p.</li>
        </ul>

        <h3>Interneto svetainės lankomumo statistika</h3>
        <p>
          UAB „Saulės bendruomenė LT“ nuolat tobulina savo interneto svetainę ir siekia, kad naudotis ja būtų kuo lengviau. Todėl UAB „Saulės bendruomenė LT“ būtina žinoti, kuri informacija yra aktualiausia svetainės lankytojams, kaip dažnai jie jungiasi, kokį turinį dažniausiai skaito, iš kokių regionų jie yra – tad renkami panašūs demografiniai bei statistiniai duomenys (IP adresas, naršymo informacija). Teisėtas statistinių duomenų rengimas ir analizė grindžiami teisėtais UAB „Saulės bendruomenė LT“ interesais stebėti ir tobulinti vykdomą veiklą. (BDAR 6 str. 1 d. f) p.)
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ renka šią informaciją naudodama automatinį „Google Analytics“ įrankį, kuris leidžia UAB „Saulės bendruomenė LT“ užfiksuoti ir analizuoti, kaip lankytojai naudojasi interneto svetaine.
        </p>
        <p>
          Skirkite savo brangaus laiko susipažinimui su Asmens duomenų tvarkymo politika ir atidžiai viską perskaitykite. Susidūrus su neaiškumais, prašome susisiekti elektroniniu paštu info@saulesbendruomene.lt, arba telefonu: +370 691 02 020
        </p>
        <p>
          <a
            href="https://support.google.com/analytics/answer/1012034?hl=lten&ref_topic=6157800"
            rel="noopener"
            target="_blank"
            title="Google Analytics informacija">
            Čia rasite daugiau informacijos
          </a>
          apie tai, kaip veikia „Google Analytics“ ir kokią informaciją šis įrankis leidžia rinkti ir analizuoti.
        </p>
        <p>
          Būdama verslo įmone, UAB „Saulės bendruomenė LT“ naudoja šiuos duomenis turėdama teisėtą interesą geriau suprasti savo klientų poreikius ir pageidavimus tam, kad galėtų geriau teikti paslaugas bei padaryti publikuojamą informaciją labiau prieinama.
        </p>
        <p>
          Bet kada galite išjungti duomenų apie Jus rinkimo funkciją „Google Analytics“, kaip tai aprašyta <a
            href="https://tools.google.com/dlpage/gaoptout/"
            rel="noopener"
            target="_blank"
            title="Išjungti Google Analytics">
            čia
          </a>.
        </p>

        <h3>
          Serverio įrašai
        </h3>
        <p>
          Serveris, kuriame talpinama UAB „Saulės bendruomenė LT“ interneto svetainė, taip pat gali įrašyti užklausas, kurias pateikiate serveriui (Jūsų atidaromos interneto svetainės adresas, Jūsų naudojamas įrenginys ir naršyklė, Jūsų IP adresas ir prisijungimo laikas). Šie duomenys yra naudojami tik techniniais tikslais – užtikrinti, kad svetainė tinkamai ir saugiai veiktų, bei išnagrinėti galimus saugumo pažeidimus.
        </p>
        <p>
          Pagrindas šių duomenų rinkimui ir naudojimui yra UAB „Saulės bendruomenė LT“ teisėtas interesas užtikrinti UAB „Saulės bendruomenė LT“ interneto svetainės techninį prieinamumą ir saugumą.
        </p>

        <h3>Slapukai (angl. Cookies)</h3>

        <h4>Kas yra slapukai?</h4>
        <p>
          Slapukas yra nedidelis tekstinis elementas, kurį interneto svetainė išsaugo Jūsų kompiuteryje ar mobiliajame įrenginyje, kai apsilankote svetainėje. Tai leidžia svetainei tam tikrą laiką prisiminti Jūsų veiksmus ir nustatymus (tokius kaip prisijungimo vardas, kalba, raidžių dydžiai ir kiti vaizdo nustatymai) tam, kad Jums nereiktų kaskart juos įvesti pakartotinai apsilankius svetainėje ar naršant po jos atskirus tinklapius.
        </p>

        <h4>Kaip UAB „Saulės bendruomenė LT“ naudoja slapukus?</h4>
        <p>
          UAB „Saulės bendruomenė LT“ naudoja slapukus, kad surinktų Jūsų IP adreso ir naršymo informaciją, tokią kaip aplankytos interneto svetainės ar kiekviename tinklapyje praleistas laikas. Tai leidžia interneto svetainei prisiminti Jūsų sistemą ir nustatymus.
        </p>
        <p>
          Kiti slapukai leidžia UAB „Saulės bendruomenė LT“ stebėti svetainės srautus ir vartotojų veiksmus svetainėje – UAB „Saulės bendruomenė LT“ šiuos duomenis naudoja lankytojų elgesio analizei bei tam, kad pagerintų lankymosi svetainėje patirtį. Šie slapukai yra įdiegiami ir naudojami „Google Analytics“ įrankio pagalba.
        </p>
        <p>
          Slapukai yra įdiegiami Jūsų įrenginyje tik Jums sutikus, išskyrus slapukus, kurie reikalingi išimtinai techniniam svetainės veikimui užtikrinti. Pažymėtina, kad, jei nesuteikiate interneto svetainei teisės naudoti slapukus, kai kurios svetainės funkcijos gali iš dalies arba visiškai neveikti.
        </p>
        <p>
          Teisinis pagrindas naudoti slapukus yra UAB „Saulės bendruomenė LT“ teisėtas interesas užtikrinti interneto svetainės techninį funkcionavimą. Tais atvejais, kai slapukai naudojami siekiant prisiminti Jūsų pasirinktis bei kaupti statistiką, teisinis pagrindas slapukų naudojimui yra Jūsų sutikimas.
        </p>

        <h4>Kaip valdyti slapukus?</h4>
        <p>
          Jūs galite bet kada vos panorėję valdyti ir / arba ištrinti slapukus – kaip tai padaryti, sužinosite čia:
          <a
            href="http://www.youronlinechoices.com/"
            rel="noopener"
            target="_blank"
            title="Valdyti slapukus">
            Your Online Choices
          </a>.
          Galite ištrinti visus Jūsų įrenginyje jau esančius slapukus; be to, daugumoje naršyklių galite nustatyti, kad jos neleistų įdiegti slapukų. Tačiau jei tai padarysite, gali tekti rankiniu būdu įvesti savo pageidaujamus nustatymus kaskart apsilankius interneto svetainėje. Be to, kai kurios paslaugos ir funkcijos gali neveikti.
        </p>

        <h3>Profiliavimas</h3>
        <p>
          UAB „Saulės bendruomenė LT“ gali naudoti interneto svetainės lankytojų statistinius duomenis tam, kad analizuotų lankomumo bei demografines tendencijas, tačiau UAB „Saulės bendruomenė LT“ nesukuria individualių asmeninių profilių kiekvienam lankytojui.
        </p>

        <h3>Kas turi prieigą prie Jūsų duomenų</h3>
        <p>
          Prieigą prie statistinių duomenų apie UAB „Saulės bendruomenė LT“ lankytojus turi UAB „Saulės bendruomenė LT“ darbuotojai, dirbantys UAB „Saulės bendruomenė LT“ rinkodaros ir IT padaliniuose, kurie yra atsakingi už šių duomenų analizę ir interneto svetainės tobulinimą.
        </p>
        <p>
          Taip pat, prieigą prie techninių įrašų gali turėti UAB „Saulės bendruomenė LT“ partneriai, kurie teikia UAB „Saulės bendruomenė LT“ interneto svetainės turinio valdymo ir aptarnavimo arba UAB „Saulės bendruomenė LT“ svetainės talpinimo (angl. hosting) paslaugas.
        </p>
        <p>
          „Google Analytics“ įrankį suteikia įmonė „Google Inc.“ (JAV įmonė), tad ji taip pat turi prieigą prie šiuo įrankiu surinktų statistinių duomenų. „Google Inc.“ yra įsipareigojusi taikyti ES-JAV Privatumo skydo principus, kurie užtikrina, kad paslaugų teikėjas atitinka ES privatumo standartų reikalavimams. Šiam teikėjui taip pat taikomi sutartiniai įpareigojimai užtikrinti privatumą. Apie tai galite paskaityti
          <a
            href="https://www.google.com/analytics/terms/dpa/dataprocessingamendment_20160909.html"
            rel="noopener"
            target="_blank"
            title="Google Analytics duomenų valdymas">
            čia
          </a>.
        </p>
        <p>
          Jūsų asmens duomenys yra saugomi Amazon Web Services EMEA SARL tiekiamoje platformoje pagal asmens duomenų laikymo ir tvarkymo tikslais sudarytas sutartis.
        </p>

        <h3>Kiek ilgai UAB „Saulės bendruomenė LT“ išsaugo duomenis</h3>
        <p>
          Asmens duomenų tvarkymas vykdant Bendrovės sudarytas sutartis su klientais – iki tinkamo sutartinių įsipareigojimų įvykdymo termino, bet ne trumpiau nei 25 metus.
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ naudoja surinktus duomenis analizei iki trejų metų.
        </p>
        <p>
          Asmens duomenų saugojimo terminas rinkodaros tikslais – iki duomenų subjekto prieštaravimo.
        </p>
        <p>
          Asmens duomenų saugojimo terminas siekiant pareikšti, vykdyti arba apginti teisinius reikalavimus – 10 metų nuo sandorių ar dalykinių santykių su klientu pabaigos dienos.
        </p>
        <p>
          Slapukai paprastai galioja trumpai (vieną dieną, savaitę ar mėnesį), bet kai kuriais atvejais gali galioti ir iki vienerių metų.
        </p>

        <h4>Gaunamų ir tvarkomų asmens duomenų kategorijos</h4>
        <p>
          Duomenų subjekto tapatybės duomenys – vardas, pavardė, asmens kodas;
        </p>
        <p>
          Kontaktiniai duomenys – gyvenamosios vietos adresas, telefono numeris, elektroninio pašto adresas;
        </p>
        <p>
          Informacija, susijusi su sutarties sudarymu ir vykdymu – elektros energijos vartojimo vietos adresas;
        </p>
        <p>
          Mokėjimo duomenys – duomenų subjekto mokami mokesčiai (rezervacijos mokestis, kasmetinis mokestis), banko sąskaitą identifikuojanti informacija, skolos Bendrovei, mokėjimų istorija;
        </p>
        <p>
          Slapukų duomenys – IP adresas, naršymo informacija (naudojamas įrenginys ir naršyklė, prisijungimo laikas).
        </p>

        <h4>Jūsų teisės ir kaip galite jomis pasinaudoti</h4>
        <p>
          Susisiekę su UAB „Saulės bendruomenė LT“ el. paštu info@saulesbendruomene.lt, galite pasinaudoti savo teise:
        </p>
        <ul>
          <li>susipažinti su tvarkomais Jūsų asmens duomenimis;</li>
          <li>prašyti ištaisyti Jūsų asmens duomenis;</li>
          <li>prašyti ištrinti Jūsų asmens duomenis;</li>
          <li>jei taikytina, atšaukti sutikimą leisti tvarkyti Jūsų asmens duomenis.</li>
        </ul>
        <p>
          Kai kuriais atvejais Jūs galite turėti teisę prašyti apriboti Jūsų asmens duomenų tvarkymą arba nesutikti, kad būtų tvarkomi Jūsų asmens duomenys.
        </p>
        <p>
          Jūs galite naudotis savo teisėmis pagal galiojančias ES ir vietos teisės aktų nuostatas.
        </p>
        <p>
          Jei Jums paprašius Jūsų asmens duomenys yra ištrinami, UAB „Saulės bendruomenė LT“ išsaugos tik tokios informacijos kopijas, kurios yra būtinos siekiant apsaugoti trečiųjų šalių teisėtus interesus, laikytis valstybės institucijų įpareigojimų, spręsti ginčus, atpažinti trikdžius arba įpareigoti laikytis bet kokių susitarimų, kuriuos esate sudarę su UAB „Saulės bendruomenė LT“.
        </p>
        <p>
          Galite išjungti slapukus per savo naršyklės nustatymus arba apsilankę čia:
        </p>
        <ul>
          <li>
            <a
              href="https://tools.google.com/dlpage/gaoptout/"
              rel="noopener"
              target="_blank"
              title="Google opt-out">
              https://tools.google.com/dlpage/gaoptout/
            </a>
          </li>
          <li>
            <a
              href="http://www.youronlinechoices.com/"
              rel="noopener"
              target="_blank"
              title="Your Online Choices">
              http://www.youronlinechoices.com/
            </a>
          </li>
        </ul>

        <h3>Teisė pateikti skundą</h3>
        <p>
          Jei manote, kad buvo pažeistos Jūsų teisės į privatumą, galite pateikti skundą atitinkamai valdžios institucijai toje ES šalyje, kurioje gyvenate. Tokių institucijų kontaktinius duomenis rasite
          <a
            href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
            rel="noopener"
            target="_blank"
            title="Skundo pateikimas">
            čia
          </a>.
        </p>

        <h2>Rinkodaros politika</h2>
        <p>
          UAB „Saulės bendruomenė LT“ siekia su klientais ir kitais naujienlaiškių gavėjais dalintis tik aktualiomis ir savalaikėmis naujienomis, teisiniais pranešimais, kvietimais į renginius bei kita informacija. UAB „Saulės bendruomenė LT“  tai įgyvendina vadovaudamasi šia Rinkodaros privatumo politika, kuri saugo Jūsų privatumo interesus.
        </p>
        <p>
          Rinkodaros privatumo politika nurodo, kokius Jūsų asmens duomenis rinkodaros tikslais renka UAB „Saulės bendruomenė LT“, kaip juos naudoja ir kam atskleidžia. Taip pat šioje politikoje pateikiama svarbi informacija apie Jūsų turimas teises, susijusias su Jūsų asmens duomenimis.
        </p>
        <p>
          Prašome susipažinti su Rinkodaros privatumo politika ir susisiekti su UAB „Saulės bendruomenė LT“, jei turite su ja susijusių klausimų ar komentarų.
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ tvarko asmens duomenis tiesioginės rinkodaros tikslais gavusi asmens duomenų subjekto sutikimą. Nepaisant to, tuo atveju, jei UAB „Saulės bendruomenė LT“, kaip duomenų valdytojas, teikdama paslaugas gavo iš savo klientų – duomenų subjektų kontaktinius asmens duomenis, UAB „Saulės bendruomenė LT“ gali juos naudoti be atskiro duomenų subjekto sutikimo savo pačios panašių paslaugų rinkodarai, jeigu klientas iš pradžių neprieštaravo dėl tokio asmens duomenų naudojimo. Tokiu atveju asmens duomenys yra tvarkomi UAB „Saulės bendruomenė LT“ teisėtų interesų (pristatyti savo paslaugas) pagrindu.
        </p>
        <p>
          Asmens kodas rinkodaros tikslais nėra renkamas.
        </p>
        <p>
          Klientams turi būti suteikiama aiški, nemokama ir lengvai įgyvendinama galimybė prieštarauti asmens duomenų tvarkymui tiesioginės rinkodaros tikslais.
        </p>
        <p>
          Asmens duomenų saugojimo terminas – iki duomenų subjekto prieštaravimo. Daugiau informacijos apie renkamus duomenis – UAB „Saulės bendruomenė LT“ Rinkodaros politikoje (žr. žemiau).
        </p>

        <h3>Kokius duomenis renka UAB „Saulės bendruomenė LT“ ir kaip juos naudoja</h3>
        <p>
          Norėdami užsiprenumeruoti UAB „Saulės bendruomenė LT“ el. paštu siunčiamus pranešimus, UAB „Saulės bendruomenė LT“ svetainėje www.saulesbendruomene.lt turite užpildyti anketą, kurioje prašoma pateikti savo kontaktinius duomenis (el. paštą).
        </p>
        <p>
          Norėdami gauti pasiūlymą www.saulesbendruomene.lt svetainėje galite užpildyti formą, kurioje prašoma pateikti savo kontaktinius duomenis (el. paštą, vardą, pavardę, miestą, gatvės numerį, telefono numerį).
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ taip pat renka Jūsų verslo kontaktinius duomenis, kai apsikeičiate vizitinėmis kortelėmis su UAB „Saulės bendruomenė LT“ susitikimuose ar renginiuose, arba kai pateikiate savo kontaktinę informaciją registruodamiesi į UAB „Saulės bendruomenė LT“ renginius.
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ rinkodaros komunikacija vykdoma el. paštu, telefonu ir stengiantis apriboti gavėjų sąrašą tik tais asmenimis, kuriems siunčiamas pranešimas yra aktualiausias.
        </p>
        <p>
          Išsiuntus pranešimą, UAB „Saulės bendruomenė LT“ gali rinki statistinius duomenis apie Jūsų elgesį su pranešimu, pavyzdžiui, ar atidarėte el. laišką, kokias nuorodas paspaudėte, kokiu įrenginiu naudojotės ir kokios jo techninės savybės. UAB „Saulės bendruomenė LT“ tai daro sekdama registruojamą informaciją apie Jūsų prieigą prie UAB „Saulės bendruomenė LT“  svetainės arba slapukų (angl. cookies) pagalba (jei duodate sutikimą).
        </p>
        <p>
          Kai užsisakote UAB „Saulės bendruomenė LT“ pranešimus interneto svetainėje, UAB „Saulės bendruomenė LT“  naudoja Jūsų asmens duomenis remdamasi Jūsų sutikimu.
        </p>
        <p>
          UAB „Saulės bendruomenė LT“ siunčia Jums rinkodaros pranešimus remdamasi UAB „Saulės bendruomenė LT“ teisėtu interesu vystyti verslo santykius su Jumis ar Jūsų atstovaujamomis įmonėmis. UAB „Saulės bendruomenė LT“ seka šios komunikacijos efektyvumo statistiką siekdama pagerinti komunikaciją.
        </p>

        <h3>Kas turi prieigą prie Jūsų asmens duomenų</h3>
        <p>
          Jūsų asmens duomenys gali būti atskleidžiami:
        </p>
        <ul>
          <li>Su jumis bendraujantiems arba už rinkodarą atsakingiems UAB „Saulės bendruomenė LT“ darbuotojams;</li>
          <li>Trečiosioms šalims, kurios teikia paslaugas UAB „Saulės bendruomenė LT“, tarp jų – rinkodaros įmonėms, informacinių technologijų paslaugų teikėjams, audito įmonėms ir kitiems tiek, kiek tai būtina rinkodaros vykdymo tikslais.</li>
        </ul>

        <h3>Kiek laiko UAB „Saulės bendruomenė LT“ saugo Jūsų asmens duomenis</h3>
        <p>
          Jūsų kontaktinė informacija ir informacija, susijusi su Jūsų verslu ar veikla, bus saugoma ir tvarkoma rinkodaros tikslais tol, kol Jūs ar Jūsų atstovaujama organizacija dirba su UAB „Saulės bendruomenė LT“. UAB „Saulės bendruomenė LT“ imasi priemonių kuo geriau užtikrinti, kad neaktualūs asmens duomenys būtų atnaujinti, o nebereikalingi asmens duomenys – ištrinti.
        </p>
        <p>
          Asmens duomenys, naudojami siunčiant naujienlaiškius, bus saugomi ir tvarkomi šiuo tikslu tol, kol, jei taikoma, atšauksite savo sutikimą, arba atsisakysite UAB „Saulės bendruomenė LT“  naujienlaiškių prenumeratos.
        </p>

        <h3>Jūsų teisės ir kaip jomis pasinaudoti</h3>
        <p>
          Susisiekę su UAB „Saulės bendruomenė LT“  el. paštu info@saulesbendruomene.lt, galite pasinaudoti šiomis teisėmis:
        </p>
        <ul>
          <li>susipažinti su tvarkomais Jūsų asmens duomenimis;</li>
          <li>prašyti ištaisyti asmens duomenis;</li>
          <li>prašyti ištrinti savo asmens duomenis;</li>
          <li>atšaukti duotą sutikimą tvarkyti Jūsų asmens duomenis.</li>
        </ul>
        <p>
          Kai kuriais atvejais galite turėti teisę reikalauti apriboti Jūsų asmens duomenų tvarkymą ar nesutikti, kad būtų tvarkomi Jūsų asmens duomenys.
        </p>
        <p>
          Kai kuriais atvejais galite turėti teisę reikalauti apriboti Jūsų asmens duomenų tvarkymą ar nesutikti, kad būtų tvarkomi Jūsų asmens duomenys.
        </p>
        <p>
          Jei Jums paprašius asmens duomenys yra ištrinami, UAB „Saulės bendruomenė LT“ išsaugos tik tokios informacijos kopijas, kurios yra būtinos siekiant apsaugoti UAB „Saulės bendruomenė LT“ ar trečiųjų šalių teisėtus interesus, laikytis valstybės institucijų įpareigojimų, spręsti ginčus, atpažinti trikdžius arba įpareigoti laikytis bet kokių susitarimų, kuriuos esate sudarę su UAB „Saulės bendruomenė LT“.
        </p>

        <h3>Teisė pateikti skundą</h3>
        <p>
          Jei manote, kad buvo pažeistos Jūsų teisės į privatumą, galite pateikti skundą atitinkamai valdžios institucijai toje ES šalyje, kurioje gyvenate. Tokių institucijų kontaktinius duomenis rasite
          <a
            href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
            rel="noopener"
            target="_blank"
            title="Skundo pateikimas">
            čia
          </a>.
        </p>
      </Wrap>
    </Sect>
  </div>
</template>

<script>
import Hero from './components/landing/Hero'
import Meta from './mixins/Meta'

export default {
  mixins: [
    Meta(
      'Privatumo politika',
      'UAB „Saulės bendruomenė LT“  tvarko kliento, jei jis yra fizinis asmuo, ir kitų fizinių asmenų (atstovų, darbuotojų ir kt.), taip pat svetainės lankytojų  asmens duomenis šiais tikslais ir teisiniais pagrindais.',
      require('@/assets/social/privacy-policy.jpg'),
    ),
  ],
  components: {
    Hero,
  },
}
</script>

<style lang="scss">
#PrivacyPolicy {
  background: $white-ter;
  text-align: left;

  .Sect {
    padding: 6rem 0;
  }

  .Hero {
    h1 {
      @include dotBefore(8rem);
    }
  }

  h2, h3, h4 {
    color: $text-color;
    text-align: left;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  ul {
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  li {
    font-size: $h4;
  }
}
</style>
